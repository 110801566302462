.receipt-editor__columns {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.receipt-editor__preview {
  display: flex;
  flex-direction: column;
  width: 280px;
  min-width: 280px;
  max-width: 280px;

  height: 430px;
}

.receipt-editor__lines {
  flex: 1;

  display: flex;
  flex-direction: column;

  border: 2px solid darkgray;

  padding: 16px 0;

  z-index: 50;

  user-select: none;
  overflow: auto;
}

.receipt-editor__controls {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 2px;
  border: 2px solid darkgray;
  margin-top: -2px;
  z-index: 10;
}

.receipt-editor__control {
  font-family: "VT323", monospace;
  font-weight: bold;

  outline: 2px solid darkgray;

  cursor: pointer;

  border: none;
  background: none;
  appearance: none;
}

.receipt-editor__control:hover {
  background: lightgray;
  border-color: gray;
}

.receipt-editor__line {
  display: flex;
  flex-direction: row;

  font-family: "VT323", monospace;

  background: white;

  border: 0 solid white;
  border-width: 0 16px;

  appearance: none;
  touch-action: manipulation;

  cursor: pointer;

  z-index: 1;
}

.receipt-editor__line_selected {
  outline: 2px solid blue;
  z-index: 100;
}

.receipt-editor__line_dragging {
  outline: 2px solid blue;
  z-index: 200;
}

.receipt-editor__line_align_left {
  justify-content: start;
  text-align: left;
}

.receipt-editor__line_align_center {
  justify-content: center;
  text-align: center;
}

.receipt-editor__line_align_right {
  justify-content: end;
  text-align: right;
}

.receipt-editor__text {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.receipt-editor__qr-code {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid lightgray;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.receipt-editor__alert-container {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.receipt-editor__line-editor {
  flex: 1;

  display: flex;
  flex-direction: column;
}
