@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

body {
  overscroll-behavior: none;
}

.ant-space {
  display: flex !important;
}

.ant-table {
  overflow-x: auto;
}

.ant-table-row-color-red * {
  color: #f5222d !important;
}

.ant-table-row-color-green * {
  color: #149f0b !important;
}

.ant-table-row-color-gray * {
  color: gray !important;
}

.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-form-item {
  margin-bottom: 0;
}

/* .ant-form-item + .ant-form-item,
.ant-form-item + .ant-row,
.ant-form-item + .ant-alert,
.ant-tabs + .ant-form-item,
.ant-alert + .ant-row,
.ant-table-wrapper + .ant-row {
  margin-top: 16px;
} */

.ant-form-horizontal > div + div {
  margin-top: 16px;
}

.ant-form-small > div + div {
  margin-top: 8px;
}

.ant-form-small .ant-form-item + .ant-form-item,
.ant-form-small .ant-form-item + .ant-row,
.ant-form-small .ant-table-wrapper + .ant-row {
  margin-top: 8px;
}

.ant-form-item-label {
  word-wrap: normal;
}

.ant-form-small .ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
}

.custom-sider,
.custom-sider .ant-menu-item,
.custom-sider .ant-menu-title-content,
.custom-sider .ant-menu-submenu,
.custom-sider .ant-menu-submenu-title,
.custom-sider .ant-menu-item-icon {
  transition: none !important;
}

.ant-menu-item.panic,
.ant-menu-item.panic:hover {
  color: white;
  background: #dc2626;
}

.custom-sider .ant-typography,
.custom-drawer .ant-typography {
  color: rgba(255, 255, 255, 0.85);
}

.custom-sider .ant-btn,
.custom-drawer .ant-btn {
  color: rgba(255, 255, 255, 0.85);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.custom-sider .ant-divider,
.custom-drawer .ant-divider {
  margin: 6px 0;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
}

.custom-sider .ant-select .ant-select-selection-item {
  color: rgba(255, 255, 255, 0.65);
}

.custom-sider .ant-select .ant-select-selector {
  background-color: #001528 !important;
  border-color: #324453 !important;
}

.custom-sider .ant-select .ant-select-arrow {
  color: rgba(255, 255, 255, 0.25);
}

.custom-sider__title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 1.25;
  margin-bottom: 4px;
  padding: 0 16px;
}

.custom-sider__version {
  position: absolute;
  right: 16px;
  bottom: 0;

  height: 16px;

  display: flex;
  align-items: center;

  font-size: 10px;
  color: rgba(255, 255, 255, 0.25);

  line-height: 1;
}

.ant-modal-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ant-modal {
  top: 0;
  padding: 0;
}

.ant-modal-content {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  max-height: 80vh;
  overflow: hidden;
}

.ant-modal-body {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.ant-alert {
  align-items: flex-start;
}

.ant-alert-icon {
  margin-top: 4px;
}

.modal_with-tabs .ant-modal-body {
  overflow: hidden;
}

.modal_with-tabs .ant-modal-body > .ant-spin-nested-loading,
.modal_with-tabs
  .ant-modal-body
  > .ant-spin-nested-loading
  > .ant-spin-container,
.modal_with-tabs .ant-modal-body .ant-tabs,
.modal_with-tabs .ant-modal-body .ant-tabs-content-holder,
.modal_with-tabs .ant-modal-body .ant-tabs-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal_with-tabs .ant-modal-body > * {
  margin: 0 -24px -24px;
}

.modal_with-tabs .ant-modal-body > .ant-tabs-top {
  margin: -24px;
}

.modal_with-tabs .ant-modal-body .ant-tabs-nav {
  margin: 0;
}

.modal_with-tabs .ant-modal-body .ant-tabs-nav-list {
  padding: 0 24px;
}

.modal_with-tabs .ant-modal-body .ant-tabs-tabpane-active {
  position: relative;
  padding: 8px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0px);
}

@media (min-width: 768px) {
  .modal_with-tabs .ant-modal-body .ant-tabs-tabpane-active {
    padding: 16px;
  }
}

@media (min-width: 992px) {
  .modal_with-tabs .ant-modal-body .ant-tabs-tabpane-active {
    padding: 24px;
  }
}

.checkbox-list .ant-card-head {
  padding: 0;
  min-height: auto;
  font-size: unset;
  line-height: 1;
}

.checkbox-list .ant-card-head-title {
  padding: 0;
}

.checkbox-list .ant-card-head .ant-row {
  padding: 8px;
}

.checkbox-list .ant-card-head .ant-row:nth-child(1) {
  padding-top: 4px;
}

.checkbox-list .ant-card-head .ant-row + .ant-row {
  border-top: 1px solid #f0f0f0;
}

.checkbox-list .ant-card-head .ant-checkbox {
  margin-right: 8px;
}

.checkbox-list .ant-card-body {
  padding: 0;
  height: 300px;
  overflow: auto;
}

.checkbox-list__item {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.checkbox-list_selectable {
  cursor: pointer;
}

.checkbox-list__item:hover {
  color: #1890ff;
}

.checkbox-list_selectable .checkbox-list__item_selected,
.checkbox-list_selectable .checkbox-list__item_selected:hover {
  box-shadow: inset 2px 0 0 0 #1890ff;
}

.checkbox-list__label {
  margin-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkbox-list-carousel .slick-list {
  margin: 0 -8px;
}

.checkbox-list-carousel .slick-slide > div {
  padding: 0 8px;
}

.checkbox-list-carousel .slick-slide > div > .ant-typography {
  padding-left: 10px;
}

.ant-notification-notice-description {
  padding-right: 0;
}

.ant-notification-notice-message + .ant-notification-notice-description {
  padding-right: 24px;
}

.history-report-actions .ant-modal-body {
  overflow: hidden;
}

.history-report-actions .ant-table {
  overflow: visible;
}

.history-report-actions__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.history-report-actions__content > div + div {
  margin-top: 8px;
}

.history-report-actions__table-container {
  overflow: auto;
}

.search {
  width: 100%;
  display: flex !important;
  flex-direction: row;
}

.search .ant-select-auto-complete {
  flex: 1;
}

.search .ant-select-clear {
  opacity: 1;
}

.search .ant-select-selection-placeholder {
  position: absolute;
  left: 11px;
  right: 11px;
}

.search__auto-complete--selected .ant-select-selector {
  background-color: #f5f5f5 !important;
}

.slot {
  text-align: center;
  font-size: 10px;
}

@media (min-width: 375px) {
  .slot {
    font-size: 12px;
  }
}

@media (min-width: 414px) {
  .slot {
    font-size: 14px;
  }
}

.ant-tabs-tabpane.subtabs {
  padding: 0 !important;
}

.ant-tabs-tabpane.subtabs .ant-tabs-nav-wrap {
  background-color: #f8f8f8;
}

.ant-tabs-tabpane.subtabs {
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden !important;
}

.ant-tabs-tabpane.subtabs,
.subtabs > .ant-space,
.subtabs > .ant-space > .ant-space-item,
.subtabs .ant-tabs {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.subtabs .ant-tabs-content {
  overflow: auto;
}

ul + h2 {
  margin-top: 8px;
}

.ant-btn-sm.ant-btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-arrow,
.ant-select-clear {
  top: 1px;
  right: 1px;
  bottom: 1px;

  width: 30px;
  height: auto;

  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.bold-amount label,
.bold-amount input,
.bold-amount .ant-input-suffix {
  font-weight: 900;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  position: absolute;
  right: -12px;
}

@media (min-width: 576px) {
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -4px;
  }
}

.ant-form-item-label-left {
  overflow: visible;
}

.ant-form-item-label-left > label {
  white-space: normal !important;
}

.picture-card--banner .ant-upload-select-picture-card {
  width: 100%;
  height: auto;
  padding: 8px;
}

.tabs--container {
  margin-left: -8px;
  margin-right: -8px;
}

.tabs--container .ant-tabs-nav {
  margin: 0;
}

.tabs--container .ant-tabs-content-holder {
  margin-top: -1px;
  padding: 8px;
  border: 1px solid #f0f0f0;
}

.ant-select-item[title="Global banners"] {
  font-weight: 900;
}

.daily-login-rewards-tabs .ant-tabs-tabpane {
  padding: 0 !important;
  margin-top: 4px;
}

.ant-descriptions-row > td {
  padding: 4px;
}

.instant-messages-details .ant-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.instant-messages-details .chat .ant-card-body {
  padding: 12px;
}

.instant-messages-details .ant-list-item {
  border: none;
  padding: 4px 0;
}

.instant-messages-details .ant-space-item span {
  font-weight: normal;
  font-size: 14px;
}

.instant-messages-details .chat-list {
  @media screen and (max-width: 768px) {
    min-height: 80dvh;
  }
}

:root:lang(ru) .ant-form-item-label > label:has(span[role="img"]) {
  display: inline-flex;
}

:root:lang(ru) .ant-form-item-label > label {
  display: inline;
  padding-left: 0.125rem;
}

:root:lang(uk) .ant-form-item-label > label:has(span[role="img"]) {
  display: inline-flex;
  margin-right: 0.25rem;
}

:root:lang(uk) .ant-form-item-label > label {
  display: inline;
  margin-left: 0.125rem;
}
